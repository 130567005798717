import React from 'react';

import { FiShare, FiTwitter, FiFacebook } from 'react-icons/fi';

const SocialSharing = () => (
  <>
    <FiShare onClick={() => console.log('copy to clippers')} />
    <FiFacebook onClick={() => console.log('share to fb')} />
    <FiTwitter onClick={() => console.log('share to tweeter')} />
  </>
)

export default SocialSharing;