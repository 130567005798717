import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from '../components/seo'
import styled from 'styled-components';

import SocialSharing from '../components/SocialSharing';

const StoryPageHero = styled.div`
  background: no-repeat center center fixed;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(155, 255, 255, 0.6)), url(https://picsum.photos/1200);
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`

const StoryTitle = styled.h1`
  color: rgba(255,255,255,0.9);
  font-size: 5rem;
`

const StorySubtitle = styled.p`
  font-weight: bold;
  font-size: 1.75rem;
  color: rgba(255,255,255,0.9);
`

const Byline = styled.p`
  font-weight: bold;
  color: rgba(255,255,255,0.75);
`

const StoryContents = styled.div`
  padding: 20px;
  width: 100vw;
  max-width: 700px;
  margin: 0 auto;
`

const ShareContainer = styled.div`
  display: none;
  @media (min-width: 48rem) {
    position: fixed;
    height: 160px;
    left: calc(100% - 50px);
    top: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    svg {
      stroke-width: 1;
      font-size: 2rem;
      color: rgba(88,43,25,0.9); 
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        color: rgba(255,155,155,0.9);
      }
    }
  }
`

export default function PostTemplate({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} />
        <StoryPageHero>
          <StoryTitle>{frontmatter.title.toUpperCase()}</StoryTitle>
          <StorySubtitle>{frontmatter.subtitle}</StorySubtitle>
          <Byline>by {frontmatter.author}</Byline>
        </StoryPageHero>
        <ShareContainer>
          <SocialSharing data={frontmatter} />
        </ShareContainer>
        <StoryContents dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        author
      }
    }
  }
`